const features = [
  {
    name: 'Bet Market Integration.',
    description:
      'Sports Technologies integrates real-time bet markets from any sportsbook and tracks user interactions.',
  },
  {
    name: 'Game Registration.',
    description:
      'Sports Technologies connects with a client’s SSO (single sign on) or we can provide our own game registration.',
  },
  {
    name: 'Turnkey Management.',
    description:
      'We are full-service, identifying good game matches and then designing, developing, testing and maintaining the project throughout its lifecycle.',
  },
  {
    name: 'Quick and Skinnable.',
    description:
      'Sports Technologies can deliver popular game types quickly, each skinnable in its unique look and feel.',
  },
  {
    name: 'Support.',
    description:
      'We’re available 24/7 for our clients during the full lifetime of their games.',
  },
  {
    name: 'Experience Counts.',
    description:
      'We’ve worked with blue chip clients for nearly 20 years. We’re trusted providers for the biggest names in sports.',
  },
]

function Services() {
    return (
      <div className="bg-white">
        <div className="mx-auto max-w-7xl px-6 lg:px-8 pb-28 lg:mt-[-80px] md:mt-[-20px]">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-4xl font-bold tracking-tight text-gray-900 font-serif font-semibold" id={"games-and-services"}>Games & Services</h2>
            <p className="mt-6 text-xl leading-8 text-gray-600">
              For nearly 20 years we've worked with the biggest names in sports including the NBA, NASCAR, Warner Media, NCAA, PGA Tour, PGA, BetMGM and more.
            </p>
          </div>
          <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 text-xl leading-7 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name}>
                <dt className="font-medium text-gray-900">{feature.name}</dt>
                <dd className="font-light mt-1 text-gray-600">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    );
}

export default Services;
