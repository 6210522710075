// const features = [
//   {
//     name: 'BetMGM.',
//     description: 'Whitelabel Survivor game.',
//   },
//   {
//     name: 'SB Nation.',
//     description: 'Whitelabel Survivor game.',
//   },
//   {
//     name: 'NBA.',
//     description: 'Something about NBA Pick\'Em. ',
//   },
//   {
//     name: 'Tipico.',
//     description: 'Scratch & Win.',
//   },
// ]

function Props() {
    return (
      <div className="overflow-hidden bg-white py-20 sm:py-24" id={"props"}>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="lg:pr-8 lg:pt-4">
              <div className="lg:max-w-lg">
                <p className="text-4xl font-bold tracking-tight text-gray-900 font-serif font-semibold">Props</p>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  Props-based games are perfect for single events or full seasons. Props can represent actual bet markets or be unique and built through an accompanying CMS tool.
                </p>
              </div>
            </div>
            <img
              src="/games/props.png"
              alt="Product screenshot"
              width={2432}
              height={1442}
            />
          </div>
        </div>
      </div>
    );
}

export default Props;
