function Roster() {
    return (
      <div className="bg-black-red py-20 sm:py-24" id={"roster"}>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl sm:text-center">
            <p className="mt-2 text-3xl font-serif font-semibold text-white">Roster Games</p>
            <p className="mt-6 text-lg leading-8 text-white">
              Roster games are an excellent tool for creating a second screen experience where users can track their scoring in real time. We've delivered salary cap, tier-based games, and other games that include player pools based on other unique categories.
            </p>
          </div>
        </div>
        <div className="relative">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <img
              src="/games/roster.png"
              alt="Bracket Games Examples"
              className="m-auto"
              width={955}
              height={608}
            />

          </div>
        </div>
      </div>
    );
}

export default Roster;
