// const features = [
//   {
//     name: 'BetMGM.',
//     description: 'Golf.',
//   },
//   {
//     name: 'NASCAR.',
//     description: '3Wide.',
//   },
//   {
//     name: 'NBA.',
//     description: 'Something about NBA QuickPlay. ',
//   },
// ]

function LowFriction() {
    return (
      <div className="overflow-hidden bg-white py-20 sm:py-24" id={"lowfriction"}>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="lg:pr-8 lg:pt-4">
              <div className="lg:max-w-lg">
                <p className="text-4xl font-bold tracking-tight text-gray-900 font-serif font-semibold">Low Friction Games</p>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  Low Friction games can be delivered as part of a Sweepstakes where the user simple loads a page and received a roster or game entry that can be played for against any event or season. And, Sports Technologies can handle all of a client's Sweepstakes logistics.
                </p>
              </div>
            </div>
            <img
              src="/games/lowfriction.png"
              alt="Product screenshot"
              width={2432}
              height={1442}
            />
          </div>
        </div>
      </div>
    );
}

export default LowFriction;
