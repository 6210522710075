import Hero from "../Components/Hero";
import Services from "../Components/Services";
import Bracket from "../Components/Bracket";
import Props from "../Components/Props";
import Roster from "../Components/Roster";
import LowFriction from "../Components/LowFriction";
import Footer from "../Components/Footer";

function Home() {
    return (
        <>
            <Hero></Hero>
            <Services></Services>
            <Bracket></Bracket>
            <Props></Props>
            <Roster></Roster>
            <LowFriction></LowFriction>
            <Footer></Footer>
        </>
    );
}

export default Home;
