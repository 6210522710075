function Bracket() {
    return (
      <div className="bg-black-red py-20 sm:py-24" id={"bracket"}>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl sm:text-center">
            <p className="mt-2 text-3xl font-serif font-semibold text-white">Bracket Games</p>
            <p className="mt-6 text-lg leading-8 text-white">
              Brackets have come a long way since we started. Two new features are odds and a matchup analysis tool. Integrating odds helps users make smarter decisions with additional pricing information from the markets. A matchup analysis tool gives users the chance to customize specific stats on how important they are for making selections.
            </p>
          </div>
        </div>
        <div className="relative">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <img
              src="/games/bracket.png"
              alt="Bracket Games Examples"
              width={1220}
              height={588}
            />

          </div>
        </div>
      </div>
    );
}

export default Bracket;
